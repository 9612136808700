import {TypedDocumentNode as DocumentNode} from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {[_ in K]?: never};
export type Incremental<T> = T | {[P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string};
  String: {input: string; output: string};
  Boolean: {input: boolean; output: boolean};
  Int: {input: number; output: number};
  Float: {input: number; output: number};
  Byte: {input: any; output: any};
  DateTime: {input: any; output: any};
  Date: {input: any; output: any};
  Time: {input: any; output: any};
  Point: {input: any; output: any};
  JSON: {input: any; output: any};
  JSONObject: {input: any; output: any};
  Upload: {input: any; output: any};
  Void: {input: any; output: any};
};

export type Query = {
  __typename?: "Query";
  _?: Maybe<Scalars["Void"]["output"]>;
  version?: Maybe<Scalars["String"]["output"]>;
  me?: Maybe<User>;
  user?: Maybe<User>;
  users: UserConnection;
  file?: Maybe<File>;
  files: FileConnection;
  logs: LogConnection;
  statistics: Statistics;
  product?: Maybe<Product>;
  products: ProductConnection;
  myPurchases: PurchaseConnection;
  purchase?: Maybe<Purchase>;
  purchases: PurchaseConnection;
  country?: Maybe<Country>;
  countries: CountryConnection;
  publicConfigs: PublicConfigConnection;
  todo?: Maybe<Todo>;
  todos: TodoConnection;
  myTodos: TodoConnection;
};

export type QueryUserArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryUsersArgs = {
  text?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Array<UserStatus>>;
  role?: InputMaybe<Array<UserRole>>;
  orderBy?: InputMaybe<UserOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryFileArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryFilesArgs = {
  text?: InputMaybe<Scalars["String"]["input"]>;
  deviceId?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
  path?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  extension?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Array<FileStatus>>;
  orderBy?: InputMaybe<FileOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryLogsArgs = {
  text?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  source?: InputMaybe<Array<LogSource>>;
  level?: InputMaybe<Array<LogLevel>>;
  orderBy?: InputMaybe<LogOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryProductArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryProductsArgs = {
  filter?: InputMaybe<ProductFilter>;
  orderBy?: InputMaybe<ProductOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryMyPurchasesArgs = {
  filter?: InputMaybe<MyPurchasesFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryPurchaseArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryPurchasesArgs = {
  filter?: InputMaybe<PurchaseFilter>;
  orderBy?: InputMaybe<PurchaseOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryCountryArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryCountriesArgs = {
  filter?: InputMaybe<CountryFilter>;
  orderBy?: InputMaybe<CountryOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryPublicConfigsArgs = {
  filter?: InputMaybe<PublicConfigFilter>;
  orderBy?: InputMaybe<PublicConfigOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryTodoArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryTodosArgs = {
  filter?: InputMaybe<TodoFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryMyTodosArgs = {
  filter?: InputMaybe<MyTodosFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  _?: Maybe<Scalars["Void"]["output"]>;
  authenticate?: Maybe<AccessToken>;
  /** @deprecated Use authenticate with Credentials Authentication Type */
  signIn?: Maybe<AccessToken>;
  /** @deprecated Use authenticate with Credentials Authentication Type */
  signUp?: Maybe<AccessToken>;
  refreshTokens?: Maybe<AccessToken>;
  checkEmail?: Maybe<CheckEmail>;
  confirmEmail?: Maybe<Scalars["Void"]["output"]>;
  resendEmailConfirmation?: Maybe<Scalars["Void"]["output"]>;
  requestResetPassword?: Maybe<Scalars["Void"]["output"]>;
  resetPassword?: Maybe<Scalars["Void"]["output"]>;
  updatePassword?: Maybe<Scalars["Void"]["output"]>;
  signOut?: Maybe<Scalars["Void"]["output"]>;
  createUser?: Maybe<User>;
  updateUser?: Maybe<User>;
  deleteUser: Scalars["Boolean"]["output"];
  removeMe: Scalars["Boolean"]["output"];
  uploadUrl: FileUploadUrl;
  uploadOverwriteUrl: FileUploadUrl;
  finishUpload: File;
  downloadUrl: FileDownloadUrl;
  updateFile?: Maybe<File>;
  deleteFile: Scalars["Boolean"]["output"];
  createLog?: Maybe<Log>;
  createProduct?: Maybe<Product>;
  updateProduct?: Maybe<Product>;
  deleteProduct: Scalars["Boolean"]["output"];
  createPurchase?: Maybe<Purchase>;
  updatePurchase?: Maybe<Purchase>;
  deletePurchase: Scalars["Boolean"]["output"];
  createCountry?: Maybe<Country>;
  updateCountry?: Maybe<Country>;
  deleteCountry: Scalars["Boolean"]["output"];
  registerUserDevice?: Maybe<RegisteredUserDevice>;
  testPushNotification?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  createPublicConfig?: Maybe<PublicConfig>;
  updatePublicConfig?: Maybe<PublicConfig>;
  deletePublicConfig: Scalars["Boolean"]["output"];
  createTodo?: Maybe<Todo>;
  updateTodo?: Maybe<Todo>;
  deleteTodo: Scalars["Boolean"]["output"];
};

export type MutationAuthenticateArgs = {
  type: AuthenticationType;
  identity: Scalars["String"]["input"];
  payload: AuthenticationPayload;
};

export type MutationSignInArgs = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  generateRefreshToken?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationSignUpArgs = {
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  upload?: InputMaybe<Scalars["Upload"]["input"]>;
};

export type MutationRefreshTokensArgs = {
  token: Scalars["String"]["input"];
};

export type MutationCheckEmailArgs = {
  email: Scalars["String"]["input"];
};

export type MutationConfirmEmailArgs = {
  token: Scalars["String"]["input"];
};

export type MutationResendEmailConfirmationArgs = {
  email: Scalars["String"]["input"];
};

export type MutationRequestResetPasswordArgs = {
  email: Scalars["String"]["input"];
};

export type MutationResetPasswordArgs = {
  token: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type MutationUpdatePasswordArgs = {
  oldPassword: Scalars["String"]["input"];
  newPassword: Scalars["String"]["input"];
};

export type MutationSignOutArgs = {
  deviceId: Scalars["ID"]["input"];
};

export type MutationCreateUserArgs = {
  user: CreateUserInput;
  image?: InputMaybe<Scalars["Upload"]["input"]>;
};

export type MutationUpdateUserArgs = {
  id: Scalars["ID"]["input"];
  user: UpdateUserInput;
  image?: InputMaybe<Scalars["Upload"]["input"]>;
};

export type MutationDeleteUserArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveMeArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationUploadUrlArgs = {
  deviceId?: InputMaybe<Scalars["String"]["input"]>;
  path?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  extension?: InputMaybe<Scalars["String"]["input"]>;
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  isPrivate?: InputMaybe<Scalars["Boolean"]["input"]>;
  tags?: InputMaybe<Scalars["JSONObject"]["input"]>;
};

export type MutationUploadOverwriteUrlArgs = {
  id: Scalars["ID"]["input"];
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<Scalars["JSONObject"]["input"]>;
};

export type MutationFinishUploadArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDownloadUrlArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationUpdateFileArgs = {
  id: Scalars["ID"]["input"];
  file: UpdateFileInput;
};

export type MutationDeleteFileArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCreateLogArgs = {
  log: CreateLogInput;
};

export type MutationCreateProductArgs = {
  product: CreateProductInput;
};

export type MutationUpdateProductArgs = {
  id: Scalars["ID"]["input"];
  product: UpdateProductInput;
};

export type MutationDeleteProductArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCreatePurchaseArgs = {
  purchase: CreatePurchaseInput;
  payload?: InputMaybe<PurchasePayload>;
};

export type MutationUpdatePurchaseArgs = {
  id: Scalars["ID"]["input"];
  purchase: UpdatePurchaseInput;
};

export type MutationDeletePurchaseArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCreateCountryArgs = {
  country: CreateCountryInput;
};

export type MutationUpdateCountryArgs = {
  id: Scalars["ID"]["input"];
  country: UpdateCountryInput;
};

export type MutationDeleteCountryArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRegisterUserDeviceArgs = {
  userDevice: RegisterUserDeviceInput;
};

export type MutationCreatePublicConfigArgs = {
  publicConfig: CreatePublicConfigInput;
};

export type MutationUpdatePublicConfigArgs = {
  id: Scalars["ID"]["input"];
  publicConfig: UpdatePublicConfigInput;
};

export type MutationDeletePublicConfigArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCreateTodoArgs = {
  todo: CreateTodoInput;
};

export type MutationUpdateTodoArgs = {
  id: Scalars["ID"]["input"];
  todo: UpdateTodoInput;
};

export type MutationDeleteTodoArgs = {
  id: Scalars["ID"]["input"];
};

export type Subscription = {
  __typename?: "Subscription";
  _?: Maybe<Scalars["Void"]["output"]>;
};

export type ConnectionPageInfo = {
  __typename?: "ConnectionPageInfo";
  hasNextPage: Scalars["Boolean"]["output"];
  endCursor?: Maybe<Scalars["String"]["output"]>;
};

export enum ConnectionOrderDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type StringFilterInput = {
  eq?: InputMaybe<Scalars["String"]["input"]>;
  eqi?: InputMaybe<Scalars["String"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  containsi?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  matchAllFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type IdFilterInput = {
  eq?: InputMaybe<Scalars["ID"]["input"]>;
  in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  matchAllFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ArrayStringFilterInput = {
  eq?: InputMaybe<Array<Scalars["String"]["input"]>>;
  eqi?: InputMaybe<Array<Scalars["String"]["input"]>>;
  overlap?: InputMaybe<Array<Scalars["String"]["input"]>>;
  contains?: InputMaybe<Array<Scalars["String"]["input"]>>;
  containsi?: InputMaybe<Array<Scalars["String"]["input"]>>;
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  matchAllFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ArrayIdFilterInput = {
  eq?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  overlap?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  contains?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  matchAllFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ArrayIntFilterInput = {
  eq?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  overlap?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  contains?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  matchAllFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ArrayFloatFilterInput = {
  eq?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  overlap?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  contains?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  matchAllFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type IntFilterInput = {
  ne?: InputMaybe<Scalars["Int"]["input"]>;
  eq?: InputMaybe<Scalars["Int"]["input"]>;
  gte?: InputMaybe<Scalars["Int"]["input"]>;
  gt?: InputMaybe<Scalars["Int"]["input"]>;
  lte?: InputMaybe<Scalars["Int"]["input"]>;
  lt?: InputMaybe<Scalars["Int"]["input"]>;
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  matchAllFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type FloatFilterInput = {
  ne?: InputMaybe<Scalars["Float"]["input"]>;
  eq?: InputMaybe<Scalars["Float"]["input"]>;
  gte?: InputMaybe<Scalars["Float"]["input"]>;
  gt?: InputMaybe<Scalars["Float"]["input"]>;
  lte?: InputMaybe<Scalars["Float"]["input"]>;
  lt?: InputMaybe<Scalars["Float"]["input"]>;
  in?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  matchAllFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BooleanFilterInput = {
  ne?: InputMaybe<Scalars["Boolean"]["input"]>;
  eq?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  matchAllFilters?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CheckEmail = {
  __typename?: "CheckEmail";
  isAvailable?: Maybe<Scalars["Boolean"]["output"]>;
  isBlacklisted?: Maybe<Scalars["Boolean"]["output"]>;
  isCorporate?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AccessToken = {
  __typename?: "AccessToken";
  userId: Scalars["String"]["output"];
  userRole: UserRole;
  authorizationType: AuthorizationType;
  token?: Maybe<Scalars["String"]["output"]>;
  refreshToken?: Maybe<Scalars["String"]["output"]>;
  expiresAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type AuthenticationPayloadForCredentials = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  upload?: InputMaybe<Scalars["Upload"]["input"]>;
};

export type AuthenticationPayloadForApple = {
  identityToken: Scalars["String"]["input"];
  realUserStatus?: InputMaybe<Scalars["Int"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
};

export type AuthenticationPayloadForGoogle = {
  clientId: Scalars["String"]["input"];
  identityToken: Scalars["String"]["input"];
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type AuthenticationPayloadForAzure = {
  clientId: Scalars["String"]["input"];
  identityToken: Scalars["String"]["input"];
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type AuthenticationPayloadForAuth0 = {
  clientID: Scalars["String"]["input"];
  identityToken: Scalars["String"]["input"];
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type AuthenticationPayload = {
  credentials?: InputMaybe<AuthenticationPayloadForCredentials>;
  apple?: InputMaybe<AuthenticationPayloadForApple>;
  google?: InputMaybe<AuthenticationPayloadForGoogle>;
  auth0?: InputMaybe<AuthenticationPayloadForAuth0>;
  azure?: InputMaybe<AuthenticationPayloadForAzure>;
};

export enum AuthenticationType {
  Credentials = "CREDENTIALS",
  Apple = "APPLE",
  Google = "GOOGLE",
  Auth0 = "AUTH0",
  Azure = "AZURE",
}

export enum AuthorizationType {
  Internal = "INTERNAL",
  Auth0 = "AUTH0",
}

export type User = {
  __typename?: "User";
  id: Scalars["ID"]["output"];
  firstName: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<UserStatus>;
  role?: Maybe<UserRole>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  imageData?: Maybe<Scalars["Byte"]["output"]>;
  nickName?: Maybe<Scalars["String"]["output"]>;
  area?: Maybe<Scalars["String"]["output"]>;
  birthday?: Maybe<Scalars["Date"]["output"]>;
  bio?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  purchases?: Maybe<PurchaseConnection>;
  todos?: Maybe<TodoConnection>;
};

export type UserImageDataArgs = {
  height?: InputMaybe<Scalars["Float"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserConnection = {
  __typename?: "UserConnection";
  edges: Array<UserEdge>;
  pageInfo: ConnectionPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserEdge = {
  __typename?: "UserEdge";
  node: User;
  cursor: Scalars["String"]["output"];
};

export type CreateUserInput = {
  firstName: Scalars["String"]["input"];
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  role?: InputMaybe<UserRole>;
  nickName?: InputMaybe<Scalars["String"]["input"]>;
  area?: InputMaybe<Scalars["String"]["input"]>;
  birthday?: InputMaybe<Scalars["Date"]["input"]>;
  bio?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateUserInput = {
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  role?: InputMaybe<UserRole>;
  nickName?: InputMaybe<Scalars["String"]["input"]>;
  area?: InputMaybe<Scalars["String"]["input"]>;
  birthday?: InputMaybe<Scalars["Date"]["input"]>;
  bio?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserOrder = {
  field: UserOrderField;
  direction: ConnectionOrderDirection;
};

export enum UserOrderField {
  Firstname = "FIRSTNAME",
  Lastname = "LASTNAME",
  Email = "EMAIL",
  Status = "STATUS",
  Role = "ROLE",
  Nickname = "NICKNAME",
  Area = "AREA",
  Birthday = "BIRTHDAY",
  Bio = "BIO",
  Country = "COUNTRY",
  CreatedAt = "CREATED_AT",
  UpdatedAt = "UPDATED_AT",
}

export enum UserStatus {
  Pending = "PENDING",
  Active = "ACTIVE",
  Blocked = "BLOCKED",
}

export enum UserRole {
  Basic = "BASIC",
  Trusted = "TRUSTED",
  Administrator = "ADMINISTRATOR",
}

export type PublicUser = {
  __typename?: "PublicUser";
  id: Scalars["ID"]["output"];
  firstName: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  imageData?: Maybe<Scalars["Byte"]["output"]>;
  nickName?: Maybe<Scalars["String"]["output"]>;
  bio?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
};

export type PublicUserImageDataArgs = {
  height?: InputMaybe<Scalars["Float"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type FileUploadUrl = {
  __typename?: "FileUploadUrl";
  id: Scalars["ID"]["output"];
  method: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
  headers?: Maybe<Scalars["JSONObject"]["output"]>;
  fields?: Maybe<Scalars["JSONObject"]["output"]>;
  contentType: Scalars["String"]["output"];
  expirationDate: Scalars["DateTime"]["output"];
};

export type FileDownloadUrl = {
  __typename?: "FileDownloadUrl";
  id: Scalars["ID"]["output"];
  url: Scalars["String"]["output"];
  expirationDate?: Maybe<Scalars["DateTime"]["output"]>;
};

export type File = {
  __typename?: "File";
  id: Scalars["ID"]["output"];
  deviceId?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
  path?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  extension?: Maybe<Scalars["String"]["output"]>;
  contentType?: Maybe<Scalars["String"]["output"]>;
  size?: Maybe<Scalars["Int"]["output"]>;
  notes?: Maybe<Scalars["String"]["output"]>;
  isPrivate?: Maybe<Scalars["Boolean"]["output"]>;
  privateData?: Maybe<FilePrivateData>;
  status: FileStatus;
  statusDate: Scalars["DateTime"]["output"];
  statusMessage?: Maybe<Scalars["String"]["output"]>;
  storageType: FileStorageType;
  storageId?: Maybe<Scalars["String"]["output"]>;
  storageUploadUrl?: Maybe<Scalars["String"]["output"]>;
  storageDownloadUrl?: Maybe<Scalars["String"]["output"]>;
  thumbnailUrl?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  createdBy: Scalars["ID"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: Scalars["ID"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedBy?: Maybe<Scalars["ID"]["output"]>;
};

export type FilePrivateData = {
  __typename?: "FilePrivateData";
  userId?: Maybe<Scalars["String"]["output"]>;
  status: FilePrivateStatus;
  statusDate: Scalars["DateTime"]["output"];
  statusMessage?: Maybe<Scalars["String"]["output"]>;
  notes?: Maybe<Scalars["String"]["output"]>;
};

export type FileConnection = {
  __typename?: "FileConnection";
  edges: Array<FileEdge>;
  pageInfo: ConnectionPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FileEdge = {
  __typename?: "FileEdge";
  node: File;
  cursor: Scalars["String"]["output"];
};

export type UpdateFileInput = {
  notes?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type FileOrder = {
  field: FileOrderField;
  direction: ConnectionOrderDirection;
};

export enum FileOrderField {
  Device = "DEVICE",
  User = "USER",
  Path = "PATH",
  Name = "NAME",
  Extension = "EXTENSION",
  PrivateStatus = "PRIVATE_STATUS",
  Status = "STATUS",
  CreatedAt = "CREATED_AT",
  UpdatedAt = "UPDATED_AT",
}

export enum FileStatus {
  PendingUpload = "PENDING_UPLOAD",
  Ready = "READY",
  Invalid = "INVALID",
}

export enum FilePrivateStatus {
  Default = "DEFAULT",
  Status1 = "STATUS1",
  Status2 = "STATUS2",
}

export enum FileStorageType {
  FileSystem = "FILE_SYSTEM",
  GoogleCloud = "GOOGLE_CLOUD",
  AmazonS3 = "AMAZON_S3",
}

export type Log = {
  __typename?: "Log";
  id: Scalars["ID"]["output"];
  source: LogSource;
  userId?: Maybe<Scalars["String"]["output"]>;
  level: LogLevel;
  title: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type LogConnection = {
  __typename?: "LogConnection";
  edges: Array<LogEdge>;
  pageInfo: ConnectionPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LogEdge = {
  __typename?: "LogEdge";
  node: Log;
  cursor: Scalars["String"]["output"];
};

export type CreateLogInput = {
  source?: InputMaybe<LogSource>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<LogLevel>;
  title: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
};

export type LogOrder = {
  field: LogOrderField;
  direction: ConnectionOrderDirection;
};

export enum LogOrderField {
  Title = "TITLE",
  Level = "LEVEL",
  Source = "SOURCE",
  CreatedAt = "CREATED_AT",
}

export enum LogSource {
  Unknown = "UNKNOWN",
  Server = "SERVER",
  Backoffice = "BACKOFFICE",
  App = "APP",
}

export enum LogLevel {
  Info = "INFO",
  Warning = "WARNING",
  Error = "ERROR",
}

export type Statistics = {
  __typename?: "Statistics";
  totalUsers: Scalars["Int"]["output"];
};

export type StatisticsTotalUsersArgs = {
  role?: InputMaybe<UserRole>;
};

export enum PurchaseType {
  Custom = "CUSTOM",
  Apple = "APPLE",
}

export type Product = {
  __typename?: "Product";
  id: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  price?: Maybe<Scalars["Float"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  createdBy: Scalars["ID"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: Scalars["ID"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedBy?: Maybe<Scalars["ID"]["output"]>;
  purchases?: Maybe<PurchaseConnection>;
};

export type ProductConnection = {
  __typename?: "ProductConnection";
  edges: Array<ProductEdge>;
  pageInfo: ConnectionPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ProductEdge = {
  __typename?: "ProductEdge";
  node: Product;
  cursor: Scalars["String"]["output"];
};

export type CreateProductInput = {
  id: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  price?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UpdateProductInput = {
  title?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ProductOrder = {
  field: ProductOrderField;
  direction: ConnectionOrderDirection;
};

export type ProductFilter = {
  title?: InputMaybe<StringFilterInput>;
  price?: InputMaybe<FloatFilterInput>;
  matchFiltersForAllProperties?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum ProductOrderField {
  Title = "TITLE",
  Price = "PRICE",
  CreatedAt = "CREATED_AT",
  CreatedBy = "CREATED_BY",
  UpdatedAt = "UPDATED_AT",
  UpdatedBy = "UPDATED_BY",
  DeletedAt = "DELETED_AT",
  DeletedBy = "DELETED_BY",
}

export type Purchase = {
  __typename?: "Purchase";
  id: Scalars["ID"]["output"];
  purchaseDate: Scalars["DateTime"]["output"];
  user: User;
  userId: Scalars["ID"]["output"];
  product: Product;
  productId: Scalars["String"]["output"];
  purchaseType?: Maybe<PurchaseType>;
  active: Scalars["Boolean"]["output"];
  transactionId: Scalars["String"]["output"];
  quantity?: Maybe<Scalars["Int"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  cancellationDate?: Maybe<Scalars["DateTime"]["output"]>;
  expiresAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  createdBy: Scalars["ID"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: Scalars["ID"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedBy?: Maybe<Scalars["ID"]["output"]>;
};

export type PurchaseConnection = {
  __typename?: "PurchaseConnection";
  edges: Array<PurchaseEdge>;
  pageInfo: ConnectionPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PurchaseEdge = {
  __typename?: "PurchaseEdge";
  node: Purchase;
  cursor: Scalars["String"]["output"];
};

export type UpdatePurchaseInput = {
  purchaseDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
  productId?: InputMaybe<Scalars["String"]["input"]>;
  purchaseType?: InputMaybe<PurchaseType>;
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  transactionId?: InputMaybe<Scalars["String"]["input"]>;
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
  price?: InputMaybe<Scalars["Float"]["input"]>;
  cancellationDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PurchaseOrder = {
  field: PurchaseOrderField;
  direction: ConnectionOrderDirection;
};

export type PurchaseFilter = {
  userId?: InputMaybe<IdFilterInput>;
  productId?: InputMaybe<StringFilterInput>;
  active?: InputMaybe<BooleanFilterInput>;
  transactionId?: InputMaybe<StringFilterInput>;
  quantity?: InputMaybe<IntFilterInput>;
  price?: InputMaybe<FloatFilterInput>;
  matchFiltersForAllProperties?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum PurchaseOrderField {
  PurchaseDate = "PURCHASE_DATE",
  UserId = "USER_ID",
  ProductId = "PRODUCT_ID",
  TransactionId = "TRANSACTION_ID",
  Quantity = "QUANTITY",
  Price = "PRICE",
  CancellationDate = "CANCELLATION_DATE",
  ExpiresAt = "EXPIRES_AT",
  CreatedAt = "CREATED_AT",
  CreatedBy = "CREATED_BY",
  UpdatedAt = "UPDATED_AT",
  UpdatedBy = "UPDATED_BY",
  DeletedAt = "DELETED_AT",
  DeletedBy = "DELETED_BY",
}

export type MyPurchasesFilter = {
  productId?: InputMaybe<IdFilterInput>;
  active?: InputMaybe<BooleanFilterInput>;
};

export type CreatePurchaseInput = {
  type: PurchaseType;
  userId?: InputMaybe<Scalars["String"]["input"]>;
  productId: Scalars["String"]["input"];
};

export type PurchasePayloadForCustom = {
  _?: InputMaybe<Scalars["String"]["input"]>;
};

export type PurchasePayloadForApple = {
  receiptData: Scalars["String"]["input"];
};

export type PurchasePayload = {
  custom?: InputMaybe<PurchasePayloadForCustom>;
  apple?: InputMaybe<PurchasePayloadForApple>;
};

export type Country = {
  __typename?: "Country";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  createdBy: Scalars["ID"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: Scalars["ID"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedBy?: Maybe<Scalars["ID"]["output"]>;
};

export type CountryConnection = {
  __typename?: "CountryConnection";
  edges: Array<CountryEdge>;
  pageInfo: ConnectionPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CountryEdge = {
  __typename?: "CountryEdge";
  node: Country;
  cursor: Scalars["String"]["output"];
};

export type CreateCountryInput = {
  name: Scalars["String"]["input"];
  code: Scalars["String"]["input"];
};

export type UpdateCountryInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
};

export type CountryOrder = {
  field: CountryOrderField;
  direction: ConnectionOrderDirection;
};

export type CountryFilter = {
  name?: InputMaybe<StringFilterInput>;
  code?: InputMaybe<StringFilterInput>;
  matchFiltersForAllProperties?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum CountryOrderField {
  Name = "NAME",
  Code = "CODE",
  CreatedAt = "CREATED_AT",
  CreatedBy = "CREATED_BY",
  UpdatedAt = "UPDATED_AT",
  UpdatedBy = "UPDATED_BY",
  DeletedAt = "DELETED_AT",
  DeletedBy = "DELETED_BY",
}

export type RegisterUserDeviceInput = {
  deviceId: Scalars["ID"]["input"];
  deviceOs: DeviceOs;
  pushNotificationsToken?: InputMaybe<Scalars["String"]["input"]>;
};

export type RegisteredUserDevice = {
  __typename?: "RegisteredUserDevice";
  id: Scalars["ID"]["output"];
  userId: Scalars["ID"]["output"];
  deviceId: Scalars["ID"]["output"];
  deviceOs: DeviceOs;
  createdAt: Scalars["DateTime"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export enum DeviceOs {
  Ios = "IOS",
  Android = "ANDROID",
}

export type PublicConfig = {
  __typename?: "PublicConfig";
  id: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  createdBy: Scalars["ID"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: Scalars["ID"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  deletedBy?: Maybe<Scalars["ID"]["output"]>;
};

export type PublicConfigConnection = {
  __typename?: "PublicConfigConnection";
  edges: Array<PublicConfigEdge>;
  pageInfo: ConnectionPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PublicConfigEdge = {
  __typename?: "PublicConfigEdge";
  node: PublicConfig;
  cursor: Scalars["String"]["output"];
};

export type CreatePublicConfigInput = {
  id: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type UpdatePublicConfigInput = {
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type PublicConfigOrder = {
  field: PublicConfigOrderField;
  direction: ConnectionOrderDirection;
};

export type PublicConfigFilter = {
  id?: InputMaybe<StringFilterInput>;
  value?: InputMaybe<StringFilterInput>;
  matchFiltersForAllProperties?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum PublicConfigOrderField {
  Id = "ID",
  Value = "VALUE",
  CreatedAt = "CREATED_AT",
  CreatedBy = "CREATED_BY",
  UpdatedAt = "UPDATED_AT",
  UpdatedBy = "UPDATED_BY",
  DeletedAt = "DELETED_AT",
  DeletedBy = "DELETED_BY",
}

export enum TodoPriority {
  Low = "LOW",
  Medium = "MEDIUM",
  High = "HIGH",
}

export type Todo = {
  __typename?: "Todo";
  id: Scalars["ID"]["output"];
  description: Scalars["String"]["output"];
  completed: Scalars["Boolean"]["output"];
  userId: Scalars["ID"]["output"];
  priority?: Maybe<TodoPriority>;
  dueDate?: Maybe<Scalars["DateTime"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type TodoConnection = {
  __typename?: "TodoConnection";
  edges: Array<TodoEdge>;
  pageInfo: ConnectionPageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TodoEdge = {
  __typename?: "TodoEdge";
  node: Todo;
  cursor: Scalars["String"]["output"];
};

export type CreateTodoInput = {
  description: Scalars["String"]["input"];
  completed: Scalars["Boolean"]["input"];
  userId: Scalars["ID"]["input"];
  priority?: InputMaybe<TodoPriority>;
  dueDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateTodoInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
  priority?: InputMaybe<TodoPriority>;
  dueDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type TodoFilter = {
  description?: InputMaybe<StringFilterInput>;
  completed?: InputMaybe<BooleanFilterInput>;
  userId?: InputMaybe<IdFilterInput>;
  matchFiltersForAllProperties?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MyTodosFilter = {
  description?: InputMaybe<StringFilterInput>;
  completed?: InputMaybe<BooleanFilterInput>;
  matchFiltersForAllProperties?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CreateCountryMutationVariables = Exact<{
  country: CreateCountryInput;
}>;

export type CreateCountryMutation = {
  __typename?: "Mutation";
  createCountry?: {
    __typename?: "Country";
    id: string;
    name: string;
    code: string;
    createdAt: any;
    createdBy: string;
    updatedBy: string;
    updatedAt: any;
  } | null;
};

export type AuthenticateMutationVariables = Exact<{
  type: AuthenticationType;
  identity: Scalars["String"]["input"];
  payload: AuthenticationPayload;
}>;

export type AuthenticateMutation = {
  __typename?: "Mutation";
  authenticate?: {
    __typename?: "AccessToken";
    userId: string;
    userRole: UserRole;
    token?: string | null;
    refreshToken?: string | null;
    expiresAt?: any | null;
    authorizationType: AuthorizationType;
  } | null;
};

export type CreateProductMutationVariables = Exact<{
  product: CreateProductInput;
}>;

export type CreateProductMutation = {
  __typename?: "Mutation";
  createProduct?: {
    __typename?: "Product";
    id: string;
    price?: number | null;
    title: string;
    createdAt: any;
    createdBy: string;
    updatedAt: any;
    updatedBy: string;
  } | null;
};

export type CreateTodoMutationVariables = Exact<{
  todo: CreateTodoInput;
}>;

export type CreateTodoMutation = {
  __typename?: "Mutation";
  createTodo?: {
    __typename?: "Todo";
    id: string;
    description: string;
    completed: boolean;
    userId: string;
    createdAt: any;
    updatedAt: any;
    user: {__typename?: "User"; id: string; createdAt: any; firstName: string; updatedAt: any};
  } | null;
};

export type CountriesQueryVariables = Exact<{
  filter?: InputMaybe<CountryFilter>;
  orderBy?: InputMaybe<CountryOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CountriesQuery = {
  __typename?: "Query";
  countries: {
    __typename?: "CountryConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "CountryEdge";
      node: {
        __typename?: "Country";
        id: string;
        name: string;
        code: string;
        createdAt: any;
        createdBy: string;
        updatedAt: any;
        updatedBy: string;
      };
    }>;
  };
};

export type CreateLogMutationVariables = Exact<{
  log: CreateLogInput;
}>;

export type CreateLogMutation = {__typename?: "Mutation"; createLog?: {__typename?: "Log"; id: string} | null};

export type CreatePublicConfigMutationVariables = Exact<{
  input: CreatePublicConfigInput;
}>;

export type CreatePublicConfigMutation = {
  __typename?: "Mutation";
  createPublicConfig?: {
    __typename?: "PublicConfig";
    id: string;
    value: string;
    createdAt: any;
    createdBy: string;
    updatedBy: string;
    updatedAt: any;
  } | null;
};

export type CreateUserMutationVariables = Exact<{
  user: CreateUserInput;
  image?: InputMaybe<Scalars["Upload"]["input"]>;
}>;

export type CreateUserMutation = {
  __typename?: "Mutation";
  createUser?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName?: string | null;
    email?: string | null;
    status?: UserStatus | null;
    role?: UserRole | null;
    nickName?: string | null;
    area?: string | null;
    birthday?: any | null;
    bio?: string | null;
    country?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type DeleteCountryMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteCountryMutation = {__typename?: "Mutation"; deleteCountry: boolean};

export type DeleteFileMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteFileMutation = {__typename?: "Mutation"; deleteFile: boolean};

export type DeleteProductMutationVariables = Exact<{
  deleteProductId: Scalars["ID"]["input"];
}>;

export type DeleteProductMutation = {__typename?: "Mutation"; deleteProduct: boolean};

export type DeleteTodoMutationVariables = Exact<{
  deleteTodoId: Scalars["ID"]["input"];
}>;

export type DeleteTodoMutation = {__typename?: "Mutation"; deleteTodo: boolean};

export type DeletePublicConfigMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeletePublicConfigMutation = {__typename?: "Mutation"; deletePublicConfig: boolean};

export type DeleteUserMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteUserMutation = {__typename?: "Mutation"; deleteUser: boolean};

export type DownloadUrlMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DownloadUrlMutation = {
  __typename?: "Mutation";
  downloadUrl: {__typename?: "FileDownloadUrl"; id: string; url: string; expirationDate?: any | null};
};

export type FileQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FileQuery = {
  __typename?: "Query";
  file?: {
    __typename?: "File";
    id: string;
    deviceId?: string | null;
    userId?: string | null;
    name: string;
    extension?: string | null;
    isPrivate?: boolean | null;
    contentType?: string | null;
    notes?: string | null;
    status: FileStatus;
    statusDate: any;
    statusMessage?: string | null;
    storageType: FileStorageType;
    storageId?: string | null;
    storageUploadUrl?: string | null;
    storageDownloadUrl?: string | null;
    thumbnailUrl?: string | null;
    createdAt: any;
    createdBy: string;
    updatedAt: any;
    updatedBy: string;
    deletedAt?: any | null;
    deletedBy?: string | null;
    privateData?: {
      __typename?: "FilePrivateData";
      userId?: string | null;
      status: FilePrivateStatus;
      statusDate: any;
      statusMessage?: string | null;
      notes?: string | null;
    } | null;
  } | null;
};

export type FilesQueryVariables = Exact<{
  text?: InputMaybe<Scalars["String"]["input"]>;
  deviceId?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["String"]["input"]>;
  path?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  extension?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Array<FileStatus> | FileStatus>;
  orderBy?: InputMaybe<FileOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type FilesQuery = {
  __typename?: "Query";
  files: {
    __typename?: "FileConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "FileEdge";
      cursor: string;
      node: {
        __typename?: "File";
        id: string;
        deviceId?: string | null;
        userId?: string | null;
        name: string;
        extension?: string | null;
        isPrivate?: boolean | null;
        contentType?: string | null;
        notes?: string | null;
        status: FileStatus;
        statusDate: any;
        statusMessage?: string | null;
        storageType: FileStorageType;
        storageId?: string | null;
        storageUploadUrl?: string | null;
        storageDownloadUrl?: string | null;
        thumbnailUrl?: string | null;
        createdAt: any;
        createdBy: string;
        updatedAt: any;
        updatedBy: string;
        deletedAt?: any | null;
        deletedBy?: string | null;
        privateData?: {
          __typename?: "FilePrivateData";
          userId?: string | null;
          status: FilePrivateStatus;
          statusDate: any;
          statusMessage?: string | null;
          notes?: string | null;
        } | null;
      };
    }>;
    pageInfo: {__typename?: "ConnectionPageInfo"; endCursor?: string | null; hasNextPage: boolean};
  };
};

export type FinishUploadMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FinishUploadMutation = {
  __typename?: "Mutation";
  finishUpload: {
    __typename?: "File";
    id: string;
    deviceId?: string | null;
    userId?: string | null;
    name: string;
    extension?: string | null;
    isPrivate?: boolean | null;
    contentType?: string | null;
    notes?: string | null;
    status: FileStatus;
    statusDate: any;
    statusMessage?: string | null;
    storageType: FileStorageType;
    storageId?: string | null;
    storageUploadUrl?: string | null;
    storageDownloadUrl?: string | null;
    thumbnailUrl?: string | null;
    createdAt: any;
    createdBy: string;
    updatedAt: any;
    updatedBy: string;
    deletedAt?: any | null;
    deletedBy?: string | null;
    privateData?: {
      __typename?: "FilePrivateData";
      userId?: string | null;
      status: FilePrivateStatus;
      statusDate: any;
      statusMessage?: string | null;
      notes?: string | null;
    } | null;
  };
};

export type MyTodosQueryVariables = Exact<{
  filter?: InputMaybe<MyTodosFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type MyTodosQuery = {
  __typename?: "Query";
  myTodos: {
    __typename?: "TodoConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "TodoEdge";
      cursor: string;
      node: {
        __typename?: "Todo";
        completed: boolean;
        description: string;
        dueDate?: any | null;
        id: string;
        imageUrl?: string | null;
        priority?: TodoPriority | null;
        updatedAt: any;
        createdAt: any;
        userId: string;
        user: {__typename?: "User"; id: string; createdAt: any; firstName: string; updatedAt: any};
      };
    }>;
    pageInfo: {__typename?: "ConnectionPageInfo"; endCursor?: string | null; hasNextPage: boolean};
  };
};

export type FragmentFileFragment = {
  __typename?: "File";
  id: string;
  deviceId?: string | null;
  userId?: string | null;
  name: string;
  extension?: string | null;
  isPrivate?: boolean | null;
  contentType?: string | null;
  notes?: string | null;
  status: FileStatus;
  statusDate: any;
  statusMessage?: string | null;
  storageType: FileStorageType;
  storageId?: string | null;
  storageUploadUrl?: string | null;
  storageDownloadUrl?: string | null;
  thumbnailUrl?: string | null;
  createdAt: any;
  createdBy: string;
  updatedAt: any;
  updatedBy: string;
  deletedAt?: any | null;
  deletedBy?: string | null;
  privateData?: {
    __typename?: "FilePrivateData";
    userId?: string | null;
    status: FilePrivateStatus;
    statusDate: any;
    statusMessage?: string | null;
    notes?: string | null;
  } | null;
};

export type LogsQueryVariables = Exact<{
  text?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  source?: InputMaybe<Array<LogSource> | LogSource>;
  level?: InputMaybe<Array<LogLevel> | LogLevel>;
  orderBy?: InputMaybe<LogOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type LogsQuery = {
  __typename?: "Query";
  logs: {
    __typename?: "LogConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "LogEdge";
      cursor: string;
      node: {
        __typename?: "Log";
        id: string;
        source: LogSource;
        level: LogLevel;
        title: string;
        description?: string | null;
        createdAt: any;
        updatedAt: any;
      };
    }>;
    pageInfo: {__typename?: "ConnectionPageInfo"; hasNextPage: boolean; endCursor?: string | null};
  };
};

export type MeQueryVariables = Exact<{[key: string]: never}>;

export type MeQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName?: string | null;
    email?: string | null;
    status?: UserStatus | null;
    role?: UserRole | null;
    imageUrl?: string | null;
    nickName?: string | null;
    area?: string | null;
    birthday?: any | null;
    bio?: string | null;
    country?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type ProductsQueryVariables = Exact<{
  filter?: InputMaybe<ProductFilter>;
  orderBy?: InputMaybe<ProductOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type ProductsQuery = {
  __typename?: "Query";
  products: {
    __typename?: "ProductConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "ProductEdge";
      cursor: string;
      node: {
        __typename?: "Product";
        id: string;
        title: string;
        price?: number | null;
        createdAt: any;
        createdBy: string;
        updatedAt: any;
        updatedBy: string;
        deletedAt?: any | null;
        deletedBy?: string | null;
      };
    }>;
    pageInfo: {__typename?: "ConnectionPageInfo"; endCursor?: string | null; hasNextPage: boolean};
  };
};

export type PublicConfigsQueryVariables = Exact<{
  filter?: InputMaybe<PublicConfigFilter>;
  orderBy?: InputMaybe<PublicConfigOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type PublicConfigsQuery = {
  __typename?: "Query";
  publicConfigs: {
    __typename?: "PublicConfigConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "PublicConfigEdge";
      node: {
        __typename?: "PublicConfig";
        id: string;
        value: string;
        updatedBy: string;
        createdBy: string;
        updatedAt: any;
        createdAt: any;
      };
    }>;
  };
};

export type RefreshTokensMutationVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type RefreshTokensMutation = {
  __typename?: "Mutation";
  refreshTokens?: {
    __typename?: "AccessToken";
    token?: string | null;
    refreshToken?: string | null;
    expiresAt?: any | null;
    authorizationType: AuthorizationType;
  } | null;
};

export type StatisticsQueryVariables = Exact<{[key: string]: never}>;

export type StatisticsQuery = {__typename?: "Query"; statistics: {__typename?: "Statistics"; totalUsers: number}};

export type UpdateCountryMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  country: UpdateCountryInput;
}>;

export type UpdateCountryMutation = {
  __typename?: "Mutation";
  updateCountry?: {
    __typename?: "Country";
    id: string;
    name: string;
    code: string;
    createdAt: any;
    createdBy: string;
    updatedBy: string;
    updatedAt: any;
  } | null;
};

export type UpdateFileMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  file: UpdateFileInput;
}>;

export type UpdateFileMutation = {
  __typename?: "Mutation";
  updateFile?: {
    __typename?: "File";
    id: string;
    deviceId?: string | null;
    userId?: string | null;
    name: string;
    extension?: string | null;
    isPrivate?: boolean | null;
    contentType?: string | null;
    notes?: string | null;
    status: FileStatus;
    statusDate: any;
    statusMessage?: string | null;
    storageType: FileStorageType;
    storageId?: string | null;
    storageUploadUrl?: string | null;
    storageDownloadUrl?: string | null;
    thumbnailUrl?: string | null;
    createdAt: any;
    createdBy: string;
    updatedAt: any;
    updatedBy: string;
    deletedAt?: any | null;
    deletedBy?: string | null;
    privateData?: {
      __typename?: "FilePrivateData";
      userId?: string | null;
      status: FilePrivateStatus;
      statusDate: any;
      statusMessage?: string | null;
      notes?: string | null;
    } | null;
  } | null;
};

export type UpdatePasswordMutationVariables = Exact<{
  oldPassword: Scalars["String"]["input"];
  newPassword: Scalars["String"]["input"];
}>;

export type UpdatePasswordMutation = {__typename?: "Mutation"; updatePassword?: any | null};

export type UpdateProductMutationVariables = Exact<{
  updateProductId: Scalars["ID"]["input"];
  product: UpdateProductInput;
}>;

export type UpdateProductMutation = {
  __typename?: "Mutation";
  updateProduct?: {
    __typename?: "Product";
    id: string;
    title: string;
    price?: number | null;
    createdAt: any;
    createdBy: string;
    updatedAt: any;
    updatedBy: string;
  } | null;
};

export type UpdatePublicConfigMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  input: UpdatePublicConfigInput;
}>;

export type UpdatePublicConfigMutation = {
  __typename?: "Mutation";
  updatePublicConfig?: {
    __typename?: "PublicConfig";
    id: string;
    value: string;
    createdAt: any;
    createdBy: string;
    updatedBy: string;
    updatedAt: any;
  } | null;
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  user: UpdateUserInput;
  image?: InputMaybe<Scalars["Upload"]["input"]>;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName?: string | null;
    email?: string | null;
    status?: UserStatus | null;
    role?: UserRole | null;
    imageUrl?: string | null;
    nickName?: string | null;
    area?: string | null;
    birthday?: any | null;
    bio?: string | null;
    country?: string | null;
    createdAt: any;
    updatedAt: any;
  } | null;
};

export type UploadOverwriteUrlMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type UploadOverwriteUrlMutation = {
  __typename?: "Mutation";
  uploadOverwriteUrl: {
    __typename?: "FileUploadUrl";
    id: string;
    method: string;
    url: string;
    headers?: any | null;
    fields?: any | null;
    contentType: string;
    expirationDate: any;
  };
};

export type UploadUrlMutationVariables = Exact<{
  deviceId?: InputMaybe<Scalars["String"]["input"]>;
  path?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  extension?: InputMaybe<Scalars["String"]["input"]>;
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  isPrivate?: InputMaybe<Scalars["Boolean"]["input"]>;
  tags?: InputMaybe<Scalars["JSONObject"]["input"]>;
}>;

export type UploadUrlMutation = {
  __typename?: "Mutation";
  uploadUrl: {
    __typename?: "FileUploadUrl";
    id: string;
    method: string;
    url: string;
    headers?: any | null;
    fields?: any | null;
    contentType: string;
    expirationDate: any;
  };
};

export type UpdateTodoMutationVariables = Exact<{
  updateTodoId: Scalars["ID"]["input"];
  todo: UpdateTodoInput;
}>;

export type UpdateTodoMutation = {
  __typename?: "Mutation";
  updateTodo?: {
    __typename?: "Todo";
    id: string;
    description: string;
    completed: boolean;
    userId: string;
    createdAt: any;
    updatedAt: any;
    user: {__typename?: "User"; id: string; createdAt: any; firstName: string; updatedAt: any};
  } | null;
};

export type UsersQueryVariables = Exact<{
  text?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Array<UserStatus> | UserStatus>;
  role?: InputMaybe<Array<UserRole> | UserRole>;
  orderBy?: InputMaybe<UserOrder>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type UsersQuery = {
  __typename?: "Query";
  users: {
    __typename?: "UserConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "UserEdge";
      node: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName?: string | null;
        email?: string | null;
        status?: UserStatus | null;
        role?: UserRole | null;
        imageUrl?: string | null;
        nickName?: string | null;
        area?: string | null;
        birthday?: any | null;
        bio?: string | null;
        country?: string | null;
        imageData?: any | null;
        createdAt: any;
        updatedAt: any;
      };
    }>;
  };
};

export type VersionQueryVariables = Exact<{[key: string]: never}>;

export type VersionQuery = {__typename?: "Query"; version?: string | null};

export const FragmentFileFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {kind: "Name", value: "FragmentFile"},
      typeCondition: {kind: "NamedType", name: {kind: "Name", value: "File"}},
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {kind: "Field", name: {kind: "Name", value: "id"}},
          {kind: "Field", name: {kind: "Name", value: "deviceId"}},
          {kind: "Field", name: {kind: "Name", value: "userId"}},
          {kind: "Field", name: {kind: "Name", value: "name"}},
          {kind: "Field", name: {kind: "Name", value: "extension"}},
          {kind: "Field", name: {kind: "Name", value: "isPrivate"}},
          {kind: "Field", name: {kind: "Name", value: "contentType"}},
          {kind: "Field", name: {kind: "Name", value: "notes"}},
          {
            kind: "Field",
            name: {kind: "Name", value: "privateData"},
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "userId"}},
                {kind: "Field", name: {kind: "Name", value: "status"}},
                {kind: "Field", name: {kind: "Name", value: "statusDate"}},
                {kind: "Field", name: {kind: "Name", value: "statusMessage"}},
                {kind: "Field", name: {kind: "Name", value: "notes"}},
              ],
            },
          },
          {kind: "Field", name: {kind: "Name", value: "status"}},
          {kind: "Field", name: {kind: "Name", value: "statusDate"}},
          {kind: "Field", name: {kind: "Name", value: "statusMessage"}},
          {kind: "Field", name: {kind: "Name", value: "storageType"}},
          {kind: "Field", name: {kind: "Name", value: "storageId"}},
          {kind: "Field", name: {kind: "Name", value: "storageUploadUrl"}},
          {kind: "Field", name: {kind: "Name", value: "storageDownloadUrl"}},
          {kind: "Field", name: {kind: "Name", value: "thumbnailUrl"}},
          {kind: "Field", name: {kind: "Name", value: "createdAt"}},
          {kind: "Field", name: {kind: "Name", value: "createdBy"}},
          {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
          {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
          {kind: "Field", name: {kind: "Name", value: "deletedAt"}},
          {kind: "Field", name: {kind: "Name", value: "deletedBy"}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<FragmentFileFragment, unknown>;
export const CreateCountryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "CreateCountry"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "country"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "CreateCountryInput"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "createCountry"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "country"},
                value: {kind: "Variable", name: {kind: "Name", value: "country"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "name"}},
                {kind: "Field", name: {kind: "Name", value: "code"}},
                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                {kind: "Field", name: {kind: "Name", value: "createdBy"}},
                {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCountryMutation, CreateCountryMutationVariables>;
export const AuthenticateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "Authenticate"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "type"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "AuthenticationType"}}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "identity"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "payload"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "AuthenticationPayload"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "authenticate"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "type"},
                value: {kind: "Variable", name: {kind: "Name", value: "type"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "identity"},
                value: {kind: "Variable", name: {kind: "Name", value: "identity"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "payload"},
                value: {kind: "Variable", name: {kind: "Name", value: "payload"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "userId"}},
                {kind: "Field", name: {kind: "Name", value: "userRole"}},
                {kind: "Field", name: {kind: "Name", value: "token"}},
                {kind: "Field", name: {kind: "Name", value: "refreshToken"}},
                {kind: "Field", name: {kind: "Name", value: "expiresAt"}},
                {kind: "Field", name: {kind: "Name", value: "authorizationType"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthenticateMutation, AuthenticateMutationVariables>;
export const CreateProductDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "CreateProduct"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "product"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "CreateProductInput"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "createProduct"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "product"},
                value: {kind: "Variable", name: {kind: "Name", value: "product"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "price"}},
                {kind: "Field", name: {kind: "Name", value: "title"}},
                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                {kind: "Field", name: {kind: "Name", value: "createdBy"}},
                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProductMutation, CreateProductMutationVariables>;
export const CreateTodoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "CreateTodo"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "todo"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "CreateTodoInput"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "createTodo"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "todo"},
                value: {kind: "Variable", name: {kind: "Name", value: "todo"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "description"}},
                {kind: "Field", name: {kind: "Name", value: "completed"}},
                {kind: "Field", name: {kind: "Name", value: "userId"}},
                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                {
                  kind: "Field",
                  name: {kind: "Name", value: "user"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {kind: "Field", name: {kind: "Name", value: "id"}},
                      {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                      {kind: "Field", name: {kind: "Name", value: "firstName"}},
                      {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTodoMutation, CreateTodoMutationVariables>;
export const CountriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "Countries"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "filter"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "CountryFilter"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "CountryOrder"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "first"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "offset"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "countries"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "filter"},
                value: {kind: "Variable", name: {kind: "Name", value: "filter"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "orderBy"},
                value: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "first"},
                value: {kind: "Variable", name: {kind: "Name", value: "first"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "offset"},
                value: {kind: "Variable", name: {kind: "Name", value: "offset"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: {kind: "Name", value: "edges"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "node"},
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {kind: "Field", name: {kind: "Name", value: "id"}},
                            {kind: "Field", name: {kind: "Name", value: "name"}},
                            {kind: "Field", name: {kind: "Name", value: "code"}},
                            {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                            {kind: "Field", name: {kind: "Name", value: "createdBy"}},
                            {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                            {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {kind: "Field", name: {kind: "Name", value: "totalCount"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CountriesQuery, CountriesQueryVariables>;
export const CreateLogDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "CreateLog"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "log"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "CreateLogInput"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "createLog"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "log"},
                value: {kind: "Variable", name: {kind: "Name", value: "log"}},
              },
            ],
            selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "id"}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateLogMutation, CreateLogMutationVariables>;
export const CreatePublicConfigDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "CreatePublicConfig"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "input"}},
          type: {
            kind: "NonNullType",
            type: {kind: "NamedType", name: {kind: "Name", value: "CreatePublicConfigInput"}},
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "createPublicConfig"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "publicConfig"},
                value: {kind: "Variable", name: {kind: "Name", value: "input"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "value"}},
                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                {kind: "Field", name: {kind: "Name", value: "createdBy"}},
                {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePublicConfigMutation, CreatePublicConfigMutationVariables>;
export const CreateUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "CreateUser"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "user"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "CreateUserInput"}}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "image"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Upload"}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "createUser"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "user"},
                value: {kind: "Variable", name: {kind: "Name", value: "user"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "image"},
                value: {kind: "Variable", name: {kind: "Name", value: "image"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "firstName"}},
                {kind: "Field", name: {kind: "Name", value: "lastName"}},
                {kind: "Field", name: {kind: "Name", value: "email"}},
                {kind: "Field", name: {kind: "Name", value: "status"}},
                {kind: "Field", name: {kind: "Name", value: "role"}},
                {kind: "Field", name: {kind: "Name", value: "nickName"}},
                {kind: "Field", name: {kind: "Name", value: "area"}},
                {kind: "Field", name: {kind: "Name", value: "birthday"}},
                {kind: "Field", name: {kind: "Name", value: "bio"}},
                {kind: "Field", name: {kind: "Name", value: "country"}},
                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteCountryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "DeleteCountry"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "deleteCountry"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCountryMutation, DeleteCountryMutationVariables>;
export const DeleteFileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "DeleteFile"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "deleteFile"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteFileMutation, DeleteFileMutationVariables>;
export const DeleteProductDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "DeleteProduct"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "deleteProductId"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "deleteProduct"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "deleteProductId"}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteProductMutation, DeleteProductMutationVariables>;
export const DeleteTodoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "DeleteTodo"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "deleteTodoId"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "deleteTodo"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "deleteTodoId"}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTodoMutation, DeleteTodoMutationVariables>;
export const DeletePublicConfigDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "DeletePublicConfig"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "deletePublicConfig"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePublicConfigMutation, DeletePublicConfigMutationVariables>;
export const DeleteUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "DeleteUser"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "deleteUser"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>;
export const DownloadUrlDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "DownloadUrl"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "downloadUrl"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "url"}},
                {kind: "Field", name: {kind: "Name", value: "expirationDate"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DownloadUrlMutation, DownloadUrlMutationVariables>;
export const FileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "File"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "file"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FragmentFile"}}],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {kind: "Name", value: "FragmentFile"},
      typeCondition: {kind: "NamedType", name: {kind: "Name", value: "File"}},
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {kind: "Field", name: {kind: "Name", value: "id"}},
          {kind: "Field", name: {kind: "Name", value: "deviceId"}},
          {kind: "Field", name: {kind: "Name", value: "userId"}},
          {kind: "Field", name: {kind: "Name", value: "name"}},
          {kind: "Field", name: {kind: "Name", value: "extension"}},
          {kind: "Field", name: {kind: "Name", value: "isPrivate"}},
          {kind: "Field", name: {kind: "Name", value: "contentType"}},
          {kind: "Field", name: {kind: "Name", value: "notes"}},
          {
            kind: "Field",
            name: {kind: "Name", value: "privateData"},
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "userId"}},
                {kind: "Field", name: {kind: "Name", value: "status"}},
                {kind: "Field", name: {kind: "Name", value: "statusDate"}},
                {kind: "Field", name: {kind: "Name", value: "statusMessage"}},
                {kind: "Field", name: {kind: "Name", value: "notes"}},
              ],
            },
          },
          {kind: "Field", name: {kind: "Name", value: "status"}},
          {kind: "Field", name: {kind: "Name", value: "statusDate"}},
          {kind: "Field", name: {kind: "Name", value: "statusMessage"}},
          {kind: "Field", name: {kind: "Name", value: "storageType"}},
          {kind: "Field", name: {kind: "Name", value: "storageId"}},
          {kind: "Field", name: {kind: "Name", value: "storageUploadUrl"}},
          {kind: "Field", name: {kind: "Name", value: "storageDownloadUrl"}},
          {kind: "Field", name: {kind: "Name", value: "thumbnailUrl"}},
          {kind: "Field", name: {kind: "Name", value: "createdAt"}},
          {kind: "Field", name: {kind: "Name", value: "createdBy"}},
          {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
          {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
          {kind: "Field", name: {kind: "Name", value: "deletedAt"}},
          {kind: "Field", name: {kind: "Name", value: "deletedBy"}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<FileQuery, FileQueryVariables>;
export const FilesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "Files"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "text"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "deviceId"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "userId"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "path"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "extension"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "notes"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "status"}},
          type: {
            kind: "ListType",
            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "FileStatus"}}},
          },
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "FileOrder"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "first"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "offset"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "files"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "text"},
                value: {kind: "Variable", name: {kind: "Name", value: "text"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "deviceId"},
                value: {kind: "Variable", name: {kind: "Name", value: "deviceId"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "userId"},
                value: {kind: "Variable", name: {kind: "Name", value: "userId"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "path"},
                value: {kind: "Variable", name: {kind: "Name", value: "path"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "name"},
                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "extension"},
                value: {kind: "Variable", name: {kind: "Name", value: "extension"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "notes"},
                value: {kind: "Variable", name: {kind: "Name", value: "notes"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "status"},
                value: {kind: "Variable", name: {kind: "Name", value: "status"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "orderBy"},
                value: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "first"},
                value: {kind: "Variable", name: {kind: "Name", value: "first"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "offset"},
                value: {kind: "Variable", name: {kind: "Name", value: "offset"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: {kind: "Name", value: "edges"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "node"},
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FragmentFile"}}],
                        },
                      },
                      {kind: "Field", name: {kind: "Name", value: "cursor"}},
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: {kind: "Name", value: "pageInfo"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {kind: "Field", name: {kind: "Name", value: "endCursor"}},
                      {kind: "Field", name: {kind: "Name", value: "hasNextPage"}},
                    ],
                  },
                },
                {kind: "Field", name: {kind: "Name", value: "totalCount"}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {kind: "Name", value: "FragmentFile"},
      typeCondition: {kind: "NamedType", name: {kind: "Name", value: "File"}},
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {kind: "Field", name: {kind: "Name", value: "id"}},
          {kind: "Field", name: {kind: "Name", value: "deviceId"}},
          {kind: "Field", name: {kind: "Name", value: "userId"}},
          {kind: "Field", name: {kind: "Name", value: "name"}},
          {kind: "Field", name: {kind: "Name", value: "extension"}},
          {kind: "Field", name: {kind: "Name", value: "isPrivate"}},
          {kind: "Field", name: {kind: "Name", value: "contentType"}},
          {kind: "Field", name: {kind: "Name", value: "notes"}},
          {
            kind: "Field",
            name: {kind: "Name", value: "privateData"},
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "userId"}},
                {kind: "Field", name: {kind: "Name", value: "status"}},
                {kind: "Field", name: {kind: "Name", value: "statusDate"}},
                {kind: "Field", name: {kind: "Name", value: "statusMessage"}},
                {kind: "Field", name: {kind: "Name", value: "notes"}},
              ],
            },
          },
          {kind: "Field", name: {kind: "Name", value: "status"}},
          {kind: "Field", name: {kind: "Name", value: "statusDate"}},
          {kind: "Field", name: {kind: "Name", value: "statusMessage"}},
          {kind: "Field", name: {kind: "Name", value: "storageType"}},
          {kind: "Field", name: {kind: "Name", value: "storageId"}},
          {kind: "Field", name: {kind: "Name", value: "storageUploadUrl"}},
          {kind: "Field", name: {kind: "Name", value: "storageDownloadUrl"}},
          {kind: "Field", name: {kind: "Name", value: "thumbnailUrl"}},
          {kind: "Field", name: {kind: "Name", value: "createdAt"}},
          {kind: "Field", name: {kind: "Name", value: "createdBy"}},
          {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
          {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
          {kind: "Field", name: {kind: "Name", value: "deletedAt"}},
          {kind: "Field", name: {kind: "Name", value: "deletedBy"}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<FilesQuery, FilesQueryVariables>;
export const FinishUploadDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "FinishUpload"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "finishUpload"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FragmentFile"}}],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {kind: "Name", value: "FragmentFile"},
      typeCondition: {kind: "NamedType", name: {kind: "Name", value: "File"}},
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {kind: "Field", name: {kind: "Name", value: "id"}},
          {kind: "Field", name: {kind: "Name", value: "deviceId"}},
          {kind: "Field", name: {kind: "Name", value: "userId"}},
          {kind: "Field", name: {kind: "Name", value: "name"}},
          {kind: "Field", name: {kind: "Name", value: "extension"}},
          {kind: "Field", name: {kind: "Name", value: "isPrivate"}},
          {kind: "Field", name: {kind: "Name", value: "contentType"}},
          {kind: "Field", name: {kind: "Name", value: "notes"}},
          {
            kind: "Field",
            name: {kind: "Name", value: "privateData"},
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "userId"}},
                {kind: "Field", name: {kind: "Name", value: "status"}},
                {kind: "Field", name: {kind: "Name", value: "statusDate"}},
                {kind: "Field", name: {kind: "Name", value: "statusMessage"}},
                {kind: "Field", name: {kind: "Name", value: "notes"}},
              ],
            },
          },
          {kind: "Field", name: {kind: "Name", value: "status"}},
          {kind: "Field", name: {kind: "Name", value: "statusDate"}},
          {kind: "Field", name: {kind: "Name", value: "statusMessage"}},
          {kind: "Field", name: {kind: "Name", value: "storageType"}},
          {kind: "Field", name: {kind: "Name", value: "storageId"}},
          {kind: "Field", name: {kind: "Name", value: "storageUploadUrl"}},
          {kind: "Field", name: {kind: "Name", value: "storageDownloadUrl"}},
          {kind: "Field", name: {kind: "Name", value: "thumbnailUrl"}},
          {kind: "Field", name: {kind: "Name", value: "createdAt"}},
          {kind: "Field", name: {kind: "Name", value: "createdBy"}},
          {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
          {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
          {kind: "Field", name: {kind: "Name", value: "deletedAt"}},
          {kind: "Field", name: {kind: "Name", value: "deletedBy"}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<FinishUploadMutation, FinishUploadMutationVariables>;
export const MyTodosDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "MyTodos"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "filter"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "MyTodosFilter"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "first"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "offset"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "myTodos"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "filter"},
                value: {kind: "Variable", name: {kind: "Name", value: "filter"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "first"},
                value: {kind: "Variable", name: {kind: "Name", value: "first"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "offset"},
                value: {kind: "Variable", name: {kind: "Name", value: "offset"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: {kind: "Name", value: "edges"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {kind: "Field", name: {kind: "Name", value: "cursor"}},
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "node"},
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {kind: "Field", name: {kind: "Name", value: "completed"}},
                            {kind: "Field", name: {kind: "Name", value: "description"}},
                            {kind: "Field", name: {kind: "Name", value: "dueDate"}},
                            {kind: "Field", name: {kind: "Name", value: "id"}},
                            {kind: "Field", name: {kind: "Name", value: "imageUrl"}},
                            {kind: "Field", name: {kind: "Name", value: "priority"}},
                            {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                            {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "user"},
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {kind: "Field", name: {kind: "Name", value: "id"}},
                                  {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                                  {kind: "Field", name: {kind: "Name", value: "firstName"}},
                                  {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                                ],
                              },
                            },
                            {kind: "Field", name: {kind: "Name", value: "userId"}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: {kind: "Name", value: "pageInfo"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {kind: "Field", name: {kind: "Name", value: "endCursor"}},
                      {kind: "Field", name: {kind: "Name", value: "hasNextPage"}},
                    ],
                  },
                },
                {kind: "Field", name: {kind: "Name", value: "totalCount"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyTodosQuery, MyTodosQueryVariables>;
export const LogsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "Logs"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "text"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "startDate"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "endDate"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "DateTime"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "source"}},
          type: {
            kind: "ListType",
            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "LogSource"}}},
          },
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "level"}},
          type: {
            kind: "ListType",
            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "LogLevel"}}},
          },
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "LogOrder"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "first"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "offset"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "logs"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "text"},
                value: {kind: "Variable", name: {kind: "Name", value: "text"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "source"},
                value: {kind: "Variable", name: {kind: "Name", value: "source"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "level"},
                value: {kind: "Variable", name: {kind: "Name", value: "level"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "orderBy"},
                value: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "first"},
                value: {kind: "Variable", name: {kind: "Name", value: "first"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "offset"},
                value: {kind: "Variable", name: {kind: "Name", value: "offset"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "startDate"},
                value: {kind: "Variable", name: {kind: "Name", value: "startDate"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "endDate"},
                value: {kind: "Variable", name: {kind: "Name", value: "endDate"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: {kind: "Name", value: "edges"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "node"},
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {kind: "Field", name: {kind: "Name", value: "id"}},
                            {kind: "Field", name: {kind: "Name", value: "source"}},
                            {kind: "Field", name: {kind: "Name", value: "level"}},
                            {kind: "Field", name: {kind: "Name", value: "title"}},
                            {kind: "Field", name: {kind: "Name", value: "description"}},
                            {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                            {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                          ],
                        },
                      },
                      {kind: "Field", name: {kind: "Name", value: "cursor"}},
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: {kind: "Name", value: "pageInfo"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {kind: "Field", name: {kind: "Name", value: "hasNextPage"}},
                      {kind: "Field", name: {kind: "Name", value: "endCursor"}},
                    ],
                  },
                },
                {kind: "Field", name: {kind: "Name", value: "totalCount"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LogsQuery, LogsQueryVariables>;
export const MeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "Me"},
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "me"},
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "firstName"}},
                {kind: "Field", name: {kind: "Name", value: "lastName"}},
                {kind: "Field", name: {kind: "Name", value: "email"}},
                {kind: "Field", name: {kind: "Name", value: "status"}},
                {kind: "Field", name: {kind: "Name", value: "role"}},
                {kind: "Field", name: {kind: "Name", value: "imageUrl"}},
                {kind: "Field", name: {kind: "Name", value: "nickName"}},
                {kind: "Field", name: {kind: "Name", value: "area"}},
                {kind: "Field", name: {kind: "Name", value: "birthday"}},
                {kind: "Field", name: {kind: "Name", value: "bio"}},
                {kind: "Field", name: {kind: "Name", value: "country"}},
                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>;
export const ProductsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "Products"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "filter"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "ProductFilter"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "ProductOrder"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "first"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "offset"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "products"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "filter"},
                value: {kind: "Variable", name: {kind: "Name", value: "filter"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "orderBy"},
                value: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "first"},
                value: {kind: "Variable", name: {kind: "Name", value: "first"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "offset"},
                value: {kind: "Variable", name: {kind: "Name", value: "offset"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: {kind: "Name", value: "edges"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {kind: "Field", name: {kind: "Name", value: "cursor"}},
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "node"},
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {kind: "Field", name: {kind: "Name", value: "id"}},
                            {kind: "Field", name: {kind: "Name", value: "title"}},
                            {kind: "Field", name: {kind: "Name", value: "price"}},
                            {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                            {kind: "Field", name: {kind: "Name", value: "createdBy"}},
                            {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                            {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
                            {kind: "Field", name: {kind: "Name", value: "deletedAt"}},
                            {kind: "Field", name: {kind: "Name", value: "deletedBy"}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: {kind: "Name", value: "pageInfo"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {kind: "Field", name: {kind: "Name", value: "endCursor"}},
                      {kind: "Field", name: {kind: "Name", value: "hasNextPage"}},
                    ],
                  },
                },
                {kind: "Field", name: {kind: "Name", value: "totalCount"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductsQuery, ProductsQueryVariables>;
export const PublicConfigsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "PublicConfigs"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "filter"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "PublicConfigFilter"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "PublicConfigOrder"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "first"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "offset"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "publicConfigs"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "filter"},
                value: {kind: "Variable", name: {kind: "Name", value: "filter"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "orderBy"},
                value: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "first"},
                value: {kind: "Variable", name: {kind: "Name", value: "first"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "offset"},
                value: {kind: "Variable", name: {kind: "Name", value: "offset"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: {kind: "Name", value: "edges"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "node"},
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {kind: "Field", name: {kind: "Name", value: "id"}},
                            {kind: "Field", name: {kind: "Name", value: "value"}},
                            {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
                            {kind: "Field", name: {kind: "Name", value: "createdBy"}},
                            {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                            {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {kind: "Field", name: {kind: "Name", value: "totalCount"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PublicConfigsQuery, PublicConfigsQueryVariables>;
export const RefreshTokensDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "RefreshTokens"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "token"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "refreshTokens"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "token"},
                value: {kind: "Variable", name: {kind: "Name", value: "token"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "token"}},
                {kind: "Field", name: {kind: "Name", value: "refreshToken"}},
                {kind: "Field", name: {kind: "Name", value: "expiresAt"}},
                {kind: "Field", name: {kind: "Name", value: "authorizationType"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefreshTokensMutation, RefreshTokensMutationVariables>;
export const StatisticsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "Statistics"},
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "statistics"},
            selectionSet: {
              kind: "SelectionSet",
              selections: [{kind: "Field", name: {kind: "Name", value: "totalUsers"}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StatisticsQuery, StatisticsQueryVariables>;
export const UpdateCountryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "UpdateCountry"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "country"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "UpdateCountryInput"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "updateCountry"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "country"},
                value: {kind: "Variable", name: {kind: "Name", value: "country"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "name"}},
                {kind: "Field", name: {kind: "Name", value: "code"}},
                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                {kind: "Field", name: {kind: "Name", value: "createdBy"}},
                {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCountryMutation, UpdateCountryMutationVariables>;
export const UpdateFileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "UpdateFile"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "file"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "UpdateFileInput"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "updateFile"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "file"},
                value: {kind: "Variable", name: {kind: "Name", value: "file"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{kind: "FragmentSpread", name: {kind: "Name", value: "FragmentFile"}}],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {kind: "Name", value: "FragmentFile"},
      typeCondition: {kind: "NamedType", name: {kind: "Name", value: "File"}},
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {kind: "Field", name: {kind: "Name", value: "id"}},
          {kind: "Field", name: {kind: "Name", value: "deviceId"}},
          {kind: "Field", name: {kind: "Name", value: "userId"}},
          {kind: "Field", name: {kind: "Name", value: "name"}},
          {kind: "Field", name: {kind: "Name", value: "extension"}},
          {kind: "Field", name: {kind: "Name", value: "isPrivate"}},
          {kind: "Field", name: {kind: "Name", value: "contentType"}},
          {kind: "Field", name: {kind: "Name", value: "notes"}},
          {
            kind: "Field",
            name: {kind: "Name", value: "privateData"},
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "userId"}},
                {kind: "Field", name: {kind: "Name", value: "status"}},
                {kind: "Field", name: {kind: "Name", value: "statusDate"}},
                {kind: "Field", name: {kind: "Name", value: "statusMessage"}},
                {kind: "Field", name: {kind: "Name", value: "notes"}},
              ],
            },
          },
          {kind: "Field", name: {kind: "Name", value: "status"}},
          {kind: "Field", name: {kind: "Name", value: "statusDate"}},
          {kind: "Field", name: {kind: "Name", value: "statusMessage"}},
          {kind: "Field", name: {kind: "Name", value: "storageType"}},
          {kind: "Field", name: {kind: "Name", value: "storageId"}},
          {kind: "Field", name: {kind: "Name", value: "storageUploadUrl"}},
          {kind: "Field", name: {kind: "Name", value: "storageDownloadUrl"}},
          {kind: "Field", name: {kind: "Name", value: "thumbnailUrl"}},
          {kind: "Field", name: {kind: "Name", value: "createdAt"}},
          {kind: "Field", name: {kind: "Name", value: "createdBy"}},
          {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
          {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
          {kind: "Field", name: {kind: "Name", value: "deletedAt"}},
          {kind: "Field", name: {kind: "Name", value: "deletedBy"}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateFileMutation, UpdateFileMutationVariables>;
export const UpdatePasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "UpdatePassword"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "oldPassword"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "newPassword"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "updatePassword"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "oldPassword"},
                value: {kind: "Variable", name: {kind: "Name", value: "oldPassword"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "newPassword"},
                value: {kind: "Variable", name: {kind: "Name", value: "newPassword"}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const UpdateProductDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "UpdateProduct"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "updateProductId"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "product"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "UpdateProductInput"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "updateProduct"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "updateProductId"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "product"},
                value: {kind: "Variable", name: {kind: "Name", value: "product"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "title"}},
                {kind: "Field", name: {kind: "Name", value: "price"}},
                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                {kind: "Field", name: {kind: "Name", value: "createdBy"}},
                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProductMutation, UpdateProductMutationVariables>;
export const UpdatePublicConfigDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "UpdatePublicConfig"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "input"}},
          type: {
            kind: "NonNullType",
            type: {kind: "NamedType", name: {kind: "Name", value: "UpdatePublicConfigInput"}},
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "updatePublicConfig"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "publicConfig"},
                value: {kind: "Variable", name: {kind: "Name", value: "input"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "value"}},
                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                {kind: "Field", name: {kind: "Name", value: "createdBy"}},
                {kind: "Field", name: {kind: "Name", value: "updatedBy"}},
                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePublicConfigMutation, UpdatePublicConfigMutationVariables>;
export const UpdateUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "UpdateUser"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "user"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "UpdateUserInput"}}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "image"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Upload"}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "updateUser"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "user"},
                value: {kind: "Variable", name: {kind: "Name", value: "user"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "image"},
                value: {kind: "Variable", name: {kind: "Name", value: "image"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "firstName"}},
                {kind: "Field", name: {kind: "Name", value: "lastName"}},
                {kind: "Field", name: {kind: "Name", value: "email"}},
                {kind: "Field", name: {kind: "Name", value: "status"}},
                {kind: "Field", name: {kind: "Name", value: "role"}},
                {kind: "Field", name: {kind: "Name", value: "imageUrl"}},
                {kind: "Field", name: {kind: "Name", value: "nickName"}},
                {kind: "Field", name: {kind: "Name", value: "area"}},
                {kind: "Field", name: {kind: "Name", value: "birthday"}},
                {kind: "Field", name: {kind: "Name", value: "bio"}},
                {kind: "Field", name: {kind: "Name", value: "country"}},
                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const UploadOverwriteUrlDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "UploadOverwriteUrl"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "id"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "uploadOverwriteUrl"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "id"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "method"}},
                {kind: "Field", name: {kind: "Name", value: "url"}},
                {kind: "Field", name: {kind: "Name", value: "headers"}},
                {kind: "Field", name: {kind: "Name", value: "fields"}},
                {kind: "Field", name: {kind: "Name", value: "contentType"}},
                {kind: "Field", name: {kind: "Name", value: "expirationDate"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadOverwriteUrlMutation, UploadOverwriteUrlMutationVariables>;
export const UploadUrlDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "UploadUrl"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "deviceId"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "path"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "name"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "String"}}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "extension"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "contentType"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "notes"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "isPrivate"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Boolean"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "tags"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "JSONObject"}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "uploadUrl"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "deviceId"},
                value: {kind: "Variable", name: {kind: "Name", value: "deviceId"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "path"},
                value: {kind: "Variable", name: {kind: "Name", value: "path"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "name"},
                value: {kind: "Variable", name: {kind: "Name", value: "name"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "extension"},
                value: {kind: "Variable", name: {kind: "Name", value: "extension"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "contentType"},
                value: {kind: "Variable", name: {kind: "Name", value: "contentType"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "notes"},
                value: {kind: "Variable", name: {kind: "Name", value: "notes"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "isPrivate"},
                value: {kind: "Variable", name: {kind: "Name", value: "isPrivate"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "tags"},
                value: {kind: "Variable", name: {kind: "Name", value: "tags"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "method"}},
                {kind: "Field", name: {kind: "Name", value: "url"}},
                {kind: "Field", name: {kind: "Name", value: "headers"}},
                {kind: "Field", name: {kind: "Name", value: "fields"}},
                {kind: "Field", name: {kind: "Name", value: "contentType"}},
                {kind: "Field", name: {kind: "Name", value: "expirationDate"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadUrlMutation, UploadUrlMutationVariables>;
export const UpdateTodoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {kind: "Name", value: "UpdateTodo"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "updateTodoId"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "ID"}}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "todo"}},
          type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "UpdateTodoInput"}}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "updateTodo"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "id"},
                value: {kind: "Variable", name: {kind: "Name", value: "updateTodoId"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "todo"},
                value: {kind: "Variable", name: {kind: "Name", value: "todo"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {kind: "Field", name: {kind: "Name", value: "id"}},
                {kind: "Field", name: {kind: "Name", value: "description"}},
                {kind: "Field", name: {kind: "Name", value: "completed"}},
                {kind: "Field", name: {kind: "Name", value: "userId"}},
                {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                {
                  kind: "Field",
                  name: {kind: "Name", value: "user"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {kind: "Field", name: {kind: "Name", value: "id"}},
                      {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                      {kind: "Field", name: {kind: "Name", value: "firstName"}},
                      {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateTodoMutation, UpdateTodoMutationVariables>;
export const UsersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "Users"},
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "text"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "firstName"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "lastName"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "email"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "String"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "status"}},
          type: {
            kind: "ListType",
            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "UserStatus"}}},
          },
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "role"}},
          type: {
            kind: "ListType",
            type: {kind: "NonNullType", type: {kind: "NamedType", name: {kind: "Name", value: "UserRole"}}},
          },
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "UserOrder"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "first"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
        {
          kind: "VariableDefinition",
          variable: {kind: "Variable", name: {kind: "Name", value: "offset"}},
          type: {kind: "NamedType", name: {kind: "Name", value: "Int"}},
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {kind: "Name", value: "users"},
            arguments: [
              {
                kind: "Argument",
                name: {kind: "Name", value: "text"},
                value: {kind: "Variable", name: {kind: "Name", value: "text"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "firstName"},
                value: {kind: "Variable", name: {kind: "Name", value: "firstName"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "lastName"},
                value: {kind: "Variable", name: {kind: "Name", value: "lastName"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "email"},
                value: {kind: "Variable", name: {kind: "Name", value: "email"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "status"},
                value: {kind: "Variable", name: {kind: "Name", value: "status"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "role"},
                value: {kind: "Variable", name: {kind: "Name", value: "role"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "orderBy"},
                value: {kind: "Variable", name: {kind: "Name", value: "orderBy"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "first"},
                value: {kind: "Variable", name: {kind: "Name", value: "first"}},
              },
              {
                kind: "Argument",
                name: {kind: "Name", value: "offset"},
                value: {kind: "Variable", name: {kind: "Name", value: "offset"}},
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: {kind: "Name", value: "edges"},
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {kind: "Name", value: "node"},
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {kind: "Field", name: {kind: "Name", value: "id"}},
                            {kind: "Field", name: {kind: "Name", value: "firstName"}},
                            {kind: "Field", name: {kind: "Name", value: "lastName"}},
                            {kind: "Field", name: {kind: "Name", value: "email"}},
                            {kind: "Field", name: {kind: "Name", value: "status"}},
                            {kind: "Field", name: {kind: "Name", value: "role"}},
                            {kind: "Field", name: {kind: "Name", value: "imageUrl"}},
                            {kind: "Field", name: {kind: "Name", value: "nickName"}},
                            {kind: "Field", name: {kind: "Name", value: "area"}},
                            {kind: "Field", name: {kind: "Name", value: "birthday"}},
                            {kind: "Field", name: {kind: "Name", value: "bio"}},
                            {kind: "Field", name: {kind: "Name", value: "country"}},
                            {kind: "Field", name: {kind: "Name", value: "imageUrl"}},
                            {
                              kind: "Field",
                              name: {kind: "Name", value: "imageData"},
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: {kind: "Name", value: "height"},
                                  value: {kind: "IntValue", value: "128"},
                                },
                                {
                                  kind: "Argument",
                                  name: {kind: "Name", value: "width"},
                                  value: {kind: "IntValue", value: "128"},
                                },
                              ],
                            },
                            {kind: "Field", name: {kind: "Name", value: "createdAt"}},
                            {kind: "Field", name: {kind: "Name", value: "updatedAt"}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {kind: "Field", name: {kind: "Name", value: "totalCount"}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsersQuery, UsersQueryVariables>;
export const VersionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {kind: "Name", value: "Version"},
      selectionSet: {kind: "SelectionSet", selections: [{kind: "Field", name: {kind: "Name", value: "version"}}]},
    },
  ],
} as unknown as DocumentNode<VersionQuery, VersionQueryVariables>;
