import {RequestOptions, Variables} from "graphql-request";
import {getSession} from "next-auth/react";

import {AuthorizationType} from "@/shared/codegen/types";
import {serverRequest} from "@/shared/request";

export async function authorizedClientRequest<T extends object, V extends Variables = Variables>(
  options: RequestOptions<V, T>
): Promise<T> {
  const session = await getSession();
  if (!session) {
    console.log("No active session");
    return {} as T;
  }
  const {serverAccessToken, accessToken, serverAuthorizationType} = session;
  if (serverAuthorizationType === AuthorizationType.Internal && !serverAccessToken) {
    console.log("Missing Access Token");
    return {} as T;
  }
  const token = serverAuthorizationType === AuthorizationType.Auth0 ? accessToken : serverAccessToken;
  return serverRequest({
    ...options,
    requestHeaders: {
      Authorization: `Bearer ${token}`,
      "Authorization-Type": serverAuthorizationType,
    },
  });
}
